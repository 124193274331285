import { Component, EventEmitter, Input, NgModule, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes} checked by tsc
 */
let nextUniqueId = 0;
class MaterialToggleComponent {
    constructor() {
        this._uniqueId = `material-slide-toggle-${++nextUniqueId}`;
        this.name = null;
        this.id = this._uniqueId;
        // tslint:disable:no-input-rename
        this.ariaLabel = null;
        this.ariaLabelledby = null;
        this.change = new EventEmitter();
    }
    /**
     * @param {?} event
     * @return {?}
     */
    onChange(event) {
        event.stopPropagation();
        this.change.emit(this.inputElement.nativeElement.checked);
    }
    /**
     * @param {?} event
     * @return {?}
     */
    onClick(event) {
        event.stopPropagation();
    }
}
MaterialToggleComponent.decorators = [
    { type: Component, args: [{
                selector: 'material-slide-toggle',
                template: `
    <div class="material-switch">
      <input #materialSlideToggle [id]="id" [attr.name]="name" type="checkbox" [attr.aria-label]="ariaLabel" [attr.aria-labelledby]="ariaLabelledby"
        (click)="onClick($event)" (change)="onChange($event)" [checked]="checked" [disabled]="disabled" [class.disabled]="disabled"
      />
      <label [attr.for]="id" [class.disabled]="disabled" class="label-primary"></label>
    </div>
  `,
                styles: [`
    .material-switch {
      display: inline-flex;
      margin: 1em;
      vertical-align: middle;
      width: auto;
    }

    .material-switch>input[type="checkbox"] {
      display: none;
    }

    .material-switch>label {
      cursor: pointer;
      height: 0px;
      position: relative;
      width: 40px;
    }

    .material-switch>label::before {
      background: #000000;
      box-shadow: inset 0px 0px 10px rgba(0, 0, 0, 0.5);
      border-radius: 8px;
      content: '';
      height: 16px;
      margin-top: -8px;
      position: absolute;
      opacity: 0.3;
      transition: all 0.4s ease-in-out;
      width: 40px;
    }

    .material-switch>label::after {
      background: #ffffff;
      border-radius: 16px;
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
      content: '';
      height: 24px;
      left: -4px;
      margin-top: -8px;
      position: absolute;
      top: -4px;
      transition: all 0.3s ease-in-out;
      width: 24px;
    }

    .material-switch>input[type="checkbox"]:checked+label::before {
      background: #3f51b5;
      opacity: 0.5;
    }

    .material-switch>input[type="checkbox"]:checked+label::after {
      background: #3f51b5;
      left: 20px;
    }

    .disabled {
      cursor: default;
    }
  `]
            },] },
];
/** @nocollapse */
MaterialToggleComponent.ctorParameters = () => [];
MaterialToggleComponent.propDecorators = {
    "name": [{ type: Input },],
    "id": [{ type: Input },],
    "checked": [{ type: Input },],
    "disabled": [{ type: Input },],
    "ariaLabel": [{ type: Input, args: ['aria-label',] },],
    "ariaLabelledby": [{ type: Input, args: ['aria-labelledby',] },],
    "change": [{ type: Output },],
    "inputElement": [{ type: ViewChild, args: ['materialSlideToggle',] },],
};

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes} checked by tsc
 */
class SlideToggleModule {
}
SlideToggleModule.decorators = [
    { type: NgModule, args: [{
                imports: [CommonModule],
                declarations: [MaterialToggleComponent],
                exports: [MaterialToggleComponent]
            },] },
];
/** @nocollapse */
SlideToggleModule.ctorParameters = () => [];

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes} checked by tsc
 */

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes} checked by tsc
 */

/**
 * @fileoverview added by tsickle
 * @suppress {checkTypes} checked by tsc
 */
/**
 * Generated bundle index. Do not edit.
 */

export { MaterialToggleComponent, SlideToggleModule };
