@import '../../node_modules/perfect-scrollbar/css/perfect-scrollbar';

/*
  TODO: Remove important flags after this bug if fixed:
  https://github.com/angular/flex-layout/issues/381
*/

perfect-scrollbar {
  position: relative;

  display: block;
  overflow: hidden;
  width: 100%;
  height: 100%;
  max-width: 100%;
  max-height: 100%;

  &[hidden] {
    display: none;
  }

  /* stylelint-disable */
  &[fxflex] {
    display: flex;
    flex-direction: column;
    -webkit-box-orient: column;
    -webkit-box-direction: column;
    height: auto;
    min-width: 0;
    min-height: 0;

    & > .ps {
      flex: 1 1 auto;
      -ms-flex: 1 1 auto;
      -webkit-box-flex: 1;
      width: auto;
      height: auto;
      min-width: 0;
      min-height: 0;
    }
  }

  &[fxlayout] {
    & > .ps,
    & > .ps > .ps-content {
      display: flex;
      flex: 1 1 auto;
      -ms-flex: 1 1 auto;
      -webkit-box-flex: 1;
      align-item: inherit;
      place-content: inherit;
      -webkit-box-pack: inherit;
      -webkit-box-align: inherit;
      flex-direction: inherit;
      -webkit-box-orient: inherit;
      -webkit-box-direction: inherit;
      width: 100%;
      height: 100%;
    }
  }

  &[fxlayout="row"] {
    & > .ps,
    & > .ps > .ps-content {
      flex-direction: row !important;
      -webkit-box-orient: row !important;
      -webkit-box-direction: row !important;
    }
  }

  &[fxlayout="column"] {
    & > .ps,
    & > .ps > .ps-content {
      flex-direction: column !important;
      -webkit-box-orient: column !important;
      -webkit-box-direction: column !important;
    }
  }
  /* stylelint-enable */

  & > .ps {
    position: static;

    display: block;
    width: inherit;
    height: inherit;
    max-width: inherit;
    max-height: inherit;

    & > .ps-overlay {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      display: block;
      overflow: hidden;

      pointer-events: none;

      .ps-indicator-top,
      .ps-indicator-left,
      .ps-indicator-right,
      .ps-indicator-bottom {
        position: absolute;

        opacity: 0;

        transition: opacity 300ms ease-in-out;
      }

      .ps-indicator-top,
      .ps-indicator-bottom {
        left: 0;

        min-width: 100%;
        min-height: 24px;
      }

      .ps-indicator-left,
      .ps-indicator-right {
        top: 0;

        min-width: 24px;
        min-height: 100%;
      }

      .ps-indicator-top {
        top: 0;
      }

      .ps-indicator-left {
        left: 0;
      }

      .ps-indicator-right {
        right: 0;
      }

      .ps-indicator-bottom {
        bottom: 0;
      }
    }

    &.ps--active-y {
      & > .ps__rail-y {
        top: 0 !important;
        right: 0 !important;
        left: auto !important;

        width: 10px;

        cursor: default;

        transition:
          width 200ms linear,
          opacity 200ms linear,
          background-color 200ms linear;

        &:hover {
          width: 15px;
        }
      }
    }

    &.ps--active-x {
      & > .ps__rail-x {
        top: auto !important;
        bottom: 0 !important;
        left: 0 !important;

        height: 10px;

        cursor: default;

        transition:
          height 200ms linear,
          opacity 200ms linear,
          background-color 200ms linear;

        &:hover {
          height: 15px;
        }
      }

      &.ps--active-y {
        & > .ps__rail-y {
          margin: 0 0 10px;
        }

        & > .ps__rail-x {
          margin: 0 10px 0 0;
        }
      }
    }

    &.ps--scrolling-y {
      & > .ps__rail-y {
        opacity: 0.9;

        background-color: #eee;
      }
    }

    &.ps--scrolling-x {
      & > .ps__rail-x {
        opacity: 0.9;

        background-color: #eee;
      }
    }
  }

  &.ps-show-always {
    & > .ps {
      &.ps--active-y {
        & > .ps__rail-y {
          opacity: 0.6;
        }
      }

      &.ps--active-x {
        & > .ps__rail-x {
          opacity: 0.6;
        }
      }
    }
  }

  &.ps-show-active {
    & > .ps {
      &.ps--active-y {
        & > .ps-overlay {
          &:not(.ps-at-top) {
            .ps-indicator-top {
              opacity: 1;

              background: linear-gradient(to bottom, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
            }
          }

          &:not(.ps-at-bottom) {
            .ps-indicator-bottom {
              opacity: 1;

              background: linear-gradient(to top, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
            }
          }
        }
      }

      &.ps--active-x {
        & > .ps-overlay {
          &:not(.ps-at-left) {
            .ps-indicator-left {
              opacity: 1;

              background: linear-gradient(to right, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
            }
          }

          &:not(.ps-at-right) {
            .ps-indicator-right {
              opacity: 1;

              background: linear-gradient(to left, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0) 100%);
            }
          }
        }
      }
    }

    &.ps-show-limits {
      & > .ps {
        &.ps--active-y {
          & > .ps-overlay {
            &.ps-at-top {
              .ps-indicator-top {
                &.ps-indicator-show {
                  opacity: 1;
                }

                background: linear-gradient(to bottom, rgba(170, 170, 170, 0.5) 0%, rgba(170, 170, 170, 0) 100%);
              }
            }

            &.ps-at-bottom {
              .ps-indicator-bottom {
                &.ps-indicator-show {
                  opacity: 1;
                }

                background: linear-gradient(to top, rgba(170, 170, 170, 0.5) 0%, rgba(170, 170, 170, 0) 100%);
              }
            }
          }
        }

        &.ps--active-x {
          & > .ps-overlay {
            &.ps-at-left {
              .ps-indicator-left {
                &.ps-indicator-show {
                  opacity: 1;
                }

                background: linear-gradient(to right, rgba(170, 170, 170, 0.5) 0%, rgba(170, 170, 170, 0) 100%);
              }
            }

            &.ps-at-right {
              .ps-indicator-right {
                &.ps-indicator-show {
                  opacity: 1;
                }

                background: linear-gradient(to left, rgba(170, 170, 170, 0.5) 0%, rgba(170, 170, 170, 0) 100%);
              }
            }
          }
        }
      }
    }
  }
}
