export const proxyPolyfillCompat = {
  deleteToken: null,
  getToken: null,
  onMessage: null,
  onBackgroundMessage: null,
  onTokenRefresh: null,
  requestPermission: null,
  setBackgroundMessageHandler: null,
  useServiceWorker: null,
  usePublicVapidKey: null,
};
